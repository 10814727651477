import styled from 'styled-components';
import { colors, maxWidthContent, updatedMedia } from 'client/styles';

export const TrustPilotBase = styled.div`
  background-color: ${colors.white};
  max-width: ${maxWidthContent};
  padding: 20px;
  width: 100%;
  margin: 0 auto;
  position: relative;

  ${updatedMedia.tablet} {
    padding: 40px;
  }

  a {
    color: ${colors.black};
  }
`;

export const Layout = styled.div`
  width: 100%;
  min-height: 20px;
  border-top: 1px solid ${colors.grey4};
`;
