import React from 'react';

import LoginButton from 'src/comps/LoginButton';
import IconHelp from 'client/icons/Help';
import IconPhone from 'client/icons/Phone';

import HeaderLogo from 'client/components/HeaderLogo';
import HeaderWithSlots, { HeaderWithSlotsProps } from 'client/components/HeaderWithSlots';
import {
  RightSideWrapper,
  SectionLabel,
  SectionLink,
  UserActions,
} from 'client/components/CommonHeader/styles';
import { BreakpointSizes } from 'src/types/device';
import { useSession } from 'src/hooks/useSession';
import { useDevice } from 'src/hooks/useDevice';
import CountrySelector from 'client/components/CountrySelector';
import { LINK_ATTRIBUTES } from 'client/components/Header/constants';
import { FormattedMessage } from 'react-intl';
import { useMarket } from 'src/hooks/useMarket';

import { Text } from './styles';
import { AFIILIATE_EBOOKING } from 'src/constants/affiliates';
import { useBusiness } from 'src/hooks/useBusiness';

type IHomeHeaderProps = Partial<HeaderWithSlotsProps> & { pageId: string };

function HomeHeader(props: IHomeHeaderProps) {
  const { center, bottom, fixed, pageId } = props;

  const { currentUserHasPrimeSubscription } = useSession();
  const { activeBreakpoint } = useDevice();
  const { phone, helpCenterUrl } = useMarket();
  const { affiliate } = useBusiness();
  const isEbooking = affiliate === AFIILIATE_EBOOKING;
  const isDesktop = activeBreakpoint >= BreakpointSizes.M;
  const isMobile = activeBreakpoint < BreakpointSizes.M;

  return (
    <HeaderWithSlots
      left={
        <HeaderLogo
          pageId={pageId}
          isPrime={currentUserHasPrimeSubscription}
        />
      }
      center={center}
      right={
        <RightSideWrapper>
          <UserActions>
            {isDesktop && phone && (
              <SectionLink href={`tel:${phone}`}>
                <SectionLabel isEbooking={isEbooking}>
                  <IconPhone />
                  <Text>{phone}</Text>
                </SectionLabel>
              </SectionLink>
            )}

            <SectionLink href={helpCenterUrl} {...LINK_ATTRIBUTES}>
              <SectionLabel data-testid="header-help" isEbooking={isEbooking}>
                <IconHelp />
                {!isMobile && (
                  <Text>
                    <FormattedMessage id="global.help" />
                  </Text>
                )}
              </SectionLabel>
            </SectionLink>
            <LoginButton isMobile />
            <CountrySelector pageId={pageId} />
          </UserActions>
        </RightSideWrapper>
      }
      bottom={bottom}
      fixed={fixed}
    />
  );
}

export default HomeHeader;
