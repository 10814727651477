import styled from 'styled-components';

import { Heading } from 'src/styles/typography/typography';
import { updatedMedia } from 'client/styles';

export const MarqueeBase = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.brand1};
  padding: 24px 0;
  overflow: hidden;
  display: flex;

  ${updatedMedia.tablet} {
    padding: 48px 0;
  }

  ${updatedMedia.desktop} {
    padding: 80px 0;
  }
`;

export const MarqueeSlide = styled.div`
  display: flex;
  animation: scroll 20s linear infinite;

  @keyframes scroll {
    0% { 
      transform: translateX(0); 
    }
    100% { 
      transform: translateX(calc(-100%));
    }
  }
`;

export const MarqueeItem = styled.div`
  display: flex;
  width: 456px;
  
  ${updatedMedia.tablet} {
    width: 1132px;
  }

  svg {
    margin-right: 80px;

    ${updatedMedia.onlyMobile} {
      width: 35px;
      height: 35px;
      margin-right: 24px;
    }
  }
`;

export const Slogan = styled(Heading)`
  font-weight: 800;
  text-transform: uppercase;
  text-wrap: nowrap;
  margin-right: 80px;

  ${updatedMedia.onlyMobile} {
    font-size: 28px;
    margin-right: 24px;
  }
`;
