import React from 'react';
import PropTypes from 'prop-types';

import { Dot, Dots, CarouselDotsBase, Container } from './styles';

const CarouselDots = ({ dots = [], current, className = 'carousel-dots' }) => {
  if (dots.length === 0 || dots.length === 1) {
    return null;
  }

  return (
    <CarouselDotsBase className={className}>
      <Container>
        <Dots current={current} total={dots.length}>
          {dots.map((item, index) => {
            const key = `${item}${index}`;
            return <Dot data-testid='carousel-dot' key={key} selected={index === current} />;
          })}
        </Dots>
      </Container>
    </CarouselDotsBase>
  );
};

CarouselDots.propTypes = {
  current: PropTypes.number,
  dots: PropTypes.instanceOf(Array),
  className: PropTypes.string
};

export default CarouselDots;
