import React from 'react';

import Page from 'src/comps/Page';
import { routes } from 'src/constants/routes';
import { getServerSidePropsHandler } from 'src/pages/homeV2/server';
import { ConfigType } from 'src/types/config';

import { SearchProvider } from 'src/context/search';
import Head from 'client/pages/Home/Head';
import HomeHeader from '../../src/pages/homeV2/HomeHeader';
import { HomeTitle } from 'src/pages/homeV2/Title';
import Footer from 'client/components/Footer';
import Hero from 'src/pages/homeV2/Hero';
import Marquee from 'src/pages/homeV2/Marquee';
import KeyFeatures from 'src/pages/homeV2/KeyFeatures';
import AmazingDestinations, { Destination } from 'src/pages/homeV2/AmazingDestinations';
import Reviews from 'src/pages/homeV2/Reviews';
import TrustPilot from 'src/comps/TrustPilot';
import AboutUs from 'src/pages/homeV2/AboutUs';
import { SubscriptionBoxConnected } from 'src/pages/homeV2/SubscriptionBoxConnected';
import { SUBSCRIPTION_BOX_HOME_PAGE } from 'src/constants/subscriptionSources';
import BuilderModal from 'src/comps/BuilderModal';
import Maze from 'src/comps/Maze';
import styles from 'src/pages/homeV2/styles.module.css';
import { Search } from 'src/pages/homeV2/Search';

const pageId = routes.home.page;

interface IHomeV2Props {
  readonly config: ConfigType;
  amazingDestinations?: Destination[];
}

function HomeV2({ config, amazingDestinations }: IHomeV2Props) {
  const { locale, language } = config;
  return (
    <Page config={config} pageId={pageId}>
      <Head />
      <Maze />
      <SearchProvider location={null}>
        <div className={styles.homeContainer}>
          <HomeHeader pageId={pageId} />
          <section className={styles.titleSection}>
            <HomeTitle />
          </section>
          <section className={styles.searchSection}>
            <Search />
          </section>
          <Hero />
          <section className={styles.keyFeaturesSection}>
            <div className={styles.sectionContainer}>
              <KeyFeatures />
            </div>
          </section>
          <section className={styles.amazingSection}>
            <div className={styles.sectionContainer}>
              <AmazingDestinations destinations={amazingDestinations} />
            </div>
          </section>
          <section className={styles.reviewsSection} data-testid="reviews-section">
            <div className={styles.sectionContainer}>
              <TrustPilot
                className={styles.trustpilot}
                locale={locale}
                language={language}
                lazyLoad
              />
              <Reviews />
            </div>
          </section>
          <Marquee />
          <section className={styles.aboutUsSection} data-testid="aboutus-wrapper">
            <div className={styles.sectionContainer}>
              <AboutUs />
            </div>
          </section>
          <SubscriptionBoxConnected source={SUBSCRIPTION_BOX_HOME_PAGE} />
          <Footer />
        </div>
      </SearchProvider>
      <BuilderModal />
    </Page>
  );
}

export const getServerSideProps = getServerSidePropsHandler;

HomeV2.pageId = pageId;
HomeV2.pagePathView = '/homeV2';

export default HomeV2;
