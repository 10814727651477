import React, { useEffect, useState } from 'react';

import { SearchMode, SearchUrl } from 'src/types/search';
import { useDevice } from 'src/hooks/useDevice';
import { BreakpointSizes } from 'src/types/device';
import { documentBodyFreeze, documentBodyUnfreeze, getPaxesfromUrlParams } from 'client/utils';
import SearchButton from 'src/pages/search/[country]/[type]/SearchButton';
import { setSearchParamsLocalStorage } from 'lib/storage';
import { useSearch } from 'src/hooks/useSearch';
import { OmniSearch } from './Omnisearch';
import { OmnisearchBtn } from './OmnisearchBtn';

import styles from './styles.module.css';
import { Suggester } from './Suggester';
import { DatePicker } from './DatePicker';
import { Paxes } from './Paxes';

enum Views {
  Desktop = 'desktop',
  Tablet = 'tablet',
  Mobile = 'mobile',
}

interface ISearchProps {
  mode: SearchMode;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onModeChange: (visualMode: SearchMode) => void;
  className?: string;
  searchUrl: SearchUrl;
}

const getModeFromBreakpoint = (activeBreakpoint: number) => {
  return activeBreakpoint >= BreakpointSizes.L ? SearchMode.Borderless : SearchMode.Readonly;
};

const getView = ({ activeBreakpoint, mode }: { activeBreakpoint: number; mode: SearchMode }) => {
  if (mode !== SearchMode.Readonly) {
    return Views.Desktop;
  }
  if (activeBreakpoint >= BreakpointSizes.M) {
    return Views.Tablet;
  }
  return Views.Mobile;
};

const componentMap = {
  [Views.Desktop]: ({ mode, onModeChange }: Pick<ISearchProps, 'mode' | 'onModeChange'>) => (
    <OmniSearch mode={mode} onChangeMode={onModeChange} />
  ),
  [Views.Tablet]: ({ onClick, onModeChange }: Pick<ISearchProps, 'onClick' | 'onModeChange'>) => (
    <OmnisearchBtn onClick={onClick} onModeChange={onModeChange} />
  ),
  [Views.Mobile]: ({ onClick, searchUrl }: Pick<ISearchProps, 'onClick' | 'searchUrl'>) => (
    <div className={styles.searchMobile}>
      <Suggester />
      <DatePicker />
      <Paxes />
      <SearchButton searchUrl={searchUrl} onClick={onClick} fullWidth preventNavigation={false} />
    </div>
  ),
};

export function Search() {
  const { oldSearchUrl } = useSearch();
  const { href } = oldSearchUrl;
  const { activeBreakpoint } = useDevice();

  const [mode, setMode] = useState<SearchMode>(getModeFromBreakpoint(activeBreakpoint));

  useEffect(() => {
    setMode(getModeFromBreakpoint(activeBreakpoint));
  }, [activeBreakpoint]);

  useEffect(() => {
    if (mode === SearchMode.Full) {
      documentBodyFreeze();
    }
    return () => documentBodyUnfreeze();
  }, [mode]);

  const onModeChange = (visualMode: SearchMode) => {
    if (activeBreakpoint >= BreakpointSizes.L) return;
    setMode(visualMode);
  };

  const handleSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setMode(SearchMode.Readonly);

    const paxes = getPaxesfromUrlParams({
      adults: href.query.adults,
      children: href.query.children,
    });

    setSearchParamsLocalStorage({
      checkIn: href.query.checkin,
      checkOut: href.query.checkout,
      adults: paxes.adults,
      children: paxes.children,
    });
  };

  const handleClickOmnisearchBtn = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setMode(SearchMode.Full);
  };

  const view = getView({ activeBreakpoint, mode });

  const handleOnclick = view === Views.Tablet ? handleClickOmnisearchBtn : handleSearch;

  const Component = componentMap[view];

  return (
    <div data-testid="search-wrapper" className={styles.content}>
      <Component
        onClick={handleOnclick}
        onModeChange={onModeChange}
        mode={mode}
        searchUrl={oldSearchUrl}
      />
    </div>
  );
}
