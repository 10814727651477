import styled, { Theme } from 'styled-components';
import get from 'lodash/get';

import s4lColors from 'src/styles/colors/colors';
import fontSizes from 'src/styles/typography/fontSizes';
import fontFamilies from 'src/styles/typography/fonts';

import DotNestedKeys from 'src/types/dotNested';

export interface TypographyProps {
  fontFamily?: DotNestedKeys<typeof fontFamilies>;
  size?: DotNestedKeys<typeof fontSizes>;
  color?: DotNestedKeys<typeof s4lColors>;
  weight?: 400 | 500 | 600 | 700;
}

type ThemedTypographyProps = TypographyProps & { theme: Theme };

export const fontMixin = ({ theme, fontFamily, size, color, weight }: ThemedTypographyProps) => `
  font-family:  ${
    fontFamily ? get(theme, `fonts.families.${fontFamily}`) : theme.fonts.families.primary
  };
  font-size: ${size ? get(theme, `fonts.sizes.${size}`) : theme.fonts.sizes.m};
  line-height: 130%;
  color: ${color ? get(theme, `colors.${color}`) : theme.colors.grey0};
  font-weight: ${weight !== undefined ? weight : 'normal'}; 
`;

export const Paragraph = styled.p.attrs<TypographyProps>(props => ({
  color: props.color || 'grey2',
  size: props.size || 'm',
  fontFamily: props.fontFamily || 'primary',
  ...props,
}))<TypographyProps>`
  ${fontMixin}
`;

export const Heading = styled.h3.attrs<TypographyProps>(props => ({
  color: props.color || 'grey2',
  size: props.size || 'm',
  fontFamily: props.fontFamily || 'primary',
  weight: props.weight || '700',
  ...props,
}))<TypographyProps>`
  ${fontMixin}
`;
