import React from 'react';
import { useIntl } from 'react-intl';

import styles from './styles.module.css';

import Image from 'src/comps/Image';

import { PATH_IMAGES_CDN } from 'src/constants/environment';
import ChevronRight from 'client/icons/ChevronRight';
import Link from 'next/link';
import Logo from 'client/components/Logo';

const AboutUs = () => {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      <div className={styles.containerText}>
        <Logo className={styles.logo} />
        <p className={styles.text}>{intl.formatMessage({ id: 'global.about-us' })}</p>
        <Link className={styles.button} href="/the-brand/home" passHref legacyBehavior={false}>
          <>
            {intl.formatMessage({ id: 'support.about-us' })}
            <ChevronRight className={styles.buttonIcon} />
          </>
        </Link>
      </div>
      <div className={styles.containerImage}>
        <Image
          alt="about us"
          layout="responsive"
          loading="lazy"
          src={`${PATH_IMAGES_CDN}/home_v2-about-us/about-us.webp`}
          srcSet={[
            `${PATH_IMAGES_CDN}/home_v2-about-us/about-us.webp 560w`,
            `${PATH_IMAGES_CDN}/home_v2-about-us/about-us_2x.webp 1120w`,
          ]}
        />
      </div>
    </div>
  );
};

export default AboutUs;
