import React from 'react';
import { useIntl } from 'react-intl';

import styles from './styles.module.css';
import DestinationCard from './DestinationCard';
import CarouselCss from 'src/comps/CarouselCss';

export interface Destination {
  id: string;
  name: string;
  images: any;
  accommodationsCount: number;
  slug: string;
}

interface AmazingDestinationsProps {
  destinations?: Destination[];
}

const AmazingDestinations = (props: AmazingDestinationsProps) => {
  const intl = useIntl();
  const { destinations } = props;
  const year = new Date().getFullYear();

  if (!destinations) return <></>;

  return (
    <div className={styles.container}>
      <h5 className={styles.title}>
        {intl.formatMessage({ id: 'global.top-trending-places-year' }, { year })}
      </h5>

      <div className={styles.carouselContainer}>
        <CarouselCss items={destinations} className={styles.carousel}>
          {item => {
            const { id, name, images, accommodationsCount, slug } = item as Destination;
            const { portrait } = images;
            const subtitle = `${intl.formatNumber(accommodationsCount)} ${intl.formatMessage({
              id: 'global.hotels',
            })}`;
            return (
              <DestinationCard
                key={id}
                title={name}
                subtitle={subtitle}
                image={portrait}
                slug={slug}
              />
            );
          }}
        </CarouselCss>
      </div>
    </div>
  );
};

export default AmazingDestinations;
