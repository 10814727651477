import React from 'react';

interface LogoProps {
  className?: string;
}

const Logo = ({ className = '' }: LogoProps) => {
  return (
    <svg
      className={className}
      width="100%"
      height="100%"
      data-testid="logo"
      viewBox="0 0 250 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M106.324 35.3539C105.944 27.7961 111.752 21.3259 119.296 20.9453C119.676 20.9181 120.056 20.9181 120.436 20.9453C128.007 20.7006 134.33 26.6543 134.547 34.2392C134.547 34.6198 134.547 35.0004 134.547 35.3811C134.927 42.9932 129.065 49.4634 121.467 49.844C121.114 49.8712 120.761 49.8712 120.436 49.844C112.837 50.0343 106.514 44.0262 106.324 36.4141C106.324 36.0335 106.324 35.7073 106.324 35.3539ZM128.36 35.3539C128.36 30.5148 125.565 26.2194 120.436 26.2194C115.361 26.2194 112.512 30.4876 112.512 35.3539C112.512 40.2474 115.361 44.5428 120.436 44.5428C125.565 44.5428 128.36 40.2474 128.36 35.3539Z" />
      <path d="M136.121 49.1649V21.6255H138.102C140.301 21.6255 142.092 23.4197 142.092 25.6218C144.235 22.8216 147.519 21.1089 151.02 20.9458V26.8724C150.423 26.7364 149.799 26.6821 149.202 26.7092C146.406 26.818 143.774 28.1773 142.092 30.4066V49.1649H136.121Z" />
      <path d="M162.797 44.4883C162.607 44.5154 162.39 44.5426 162.2 44.5426C160.49 44.5426 159.594 43.1289 159.594 41.2259V12.0825C156.311 12.0825 153.624 14.7467 153.624 18.0634V42.5852C153.597 46.5272 156.745 49.7623 160.68 49.8167C161.413 49.8167 162.118 49.7623 162.824 49.6264L162.797 44.4883Z" />
      <path d="M221.252 44.5429C219.542 44.5429 218.646 43.1292 218.646 41.2262V32.9888V29.7537C219.189 25.4039 216.096 21.4348 211.754 20.9182C211.102 20.8367 210.424 20.8367 209.773 20.9182C206.055 20.9454 202.527 22.5222 200.03 25.3224C200.03 23.2834 198.375 21.6251 196.339 21.6251H194.033V49.1645H200.003V29.8896C201.686 27.6876 204.291 26.3554 207.059 26.2467C210.424 26.2467 212.649 27.606 212.649 31.9558V38.3717H212.676V42.7486C212.242 46.2284 214.712 49.4364 218.185 49.8714C218.701 49.9258 219.216 49.9258 219.732 49.8714C220.6 49.8714 221.442 49.7898 222.283 49.5995V44.4342C221.93 44.4885 221.604 44.5429 221.252 44.5429Z" />
      <path d="M223.449 56.2334L226.244 51.9108C228.469 54.3576 231.699 55.6625 235.009 55.445C238.998 55.9344 242.635 53.0798 243.123 49.0835C243.178 48.6485 243.178 48.1864 243.15 47.7514V44.625C241.115 47.4795 237.832 49.1922 234.331 49.2466C227.329 49.2466 222.146 44.1628 222.146 35.1099C222.146 26.1656 227.221 20.9731 234.331 20.9731C237.832 20.9459 241.115 22.6315 243.15 25.486C243.15 23.3927 244.86 21.6799 246.95 21.6799H249.121V47.7786C249.121 57.7015 241.712 60.3657 235.009 60.3657C230.749 60.5832 226.597 59.088 223.449 56.2334ZM243.15 40.1937V29.8629C241.468 27.6337 238.863 26.3016 236.095 26.22C231.319 26.22 228.306 29.7542 228.306 35.0555C228.306 40.3568 231.319 43.8366 236.095 43.8366C238.863 43.7822 241.468 42.4229 243.15 40.1937Z" />
      <path d="M104.968 17.0034C105.809 16.9762 106.65 17.1937 107.356 17.6287L108.658 13.1158C107.166 12.3818 105.483 12.0283 103.828 12.0827C98.8076 12.0827 95.1712 15.4538 95.1712 21.5978V49.1373H101.141V26.7904H106.732V21.625H101.141C101.141 18.5258 102.58 17.0034 104.968 17.0034Z" />
      <path d="M65.9982 44.5426C64.2886 44.5426 63.393 43.1289 63.393 41.2259V21.6247H63.2302C60.9778 21.6247 58.9154 22.9297 57.9655 24.9958V24.9143C55.9302 22.386 52.8366 20.9179 49.5801 20.9179C42.5244 20.9179 37.3954 26.4367 37.3954 35.3537C37.3954 44.461 42.5786 49.8439 49.5801 49.8439C52.8094 49.8439 55.8488 48.403 57.9384 45.9291C58.9696 48.5661 61.6562 50.1973 64.4785 49.8439C65.3198 49.8439 66.161 49.7623 66.9752 49.572V44.4067C66.6495 44.4882 66.3239 44.5426 65.9982 44.5426ZM51.3169 44.5426C46.5407 44.5426 43.5284 40.7094 43.5284 35.3537C43.5284 30.0524 46.5407 26.2192 51.3169 26.2192C53.5964 26.2736 55.7674 27.1979 57.3957 28.7747V41.9871C55.7674 43.5639 53.5964 44.461 51.3169 44.5426Z" />
      <path d="M34.8986 44.4617C34.6544 44.5161 34.383 44.5433 34.1388 44.5433C32.4291 44.5433 31.5336 43.1296 31.5336 41.2266V26.7908H37.1239V21.6254H31.5336V12.1103C28.2499 12.1103 25.5633 14.7745 25.5633 18.0912V42.7218C25.102 46.2016 27.5444 49.3824 31.018 49.8446C31.5607 49.9261 32.1034 49.9261 32.6191 49.8446C33.406 49.8446 34.1659 49.763 34.9257 49.6271L34.8986 44.4617Z" />
      <path d="M6.59439 29.074C6.59439 27.1438 8.52114 25.6486 11.6691 25.6486C14.2471 25.6214 16.7438 26.4642 18.7791 28.0138L22.3069 24.7243C19.349 22.1959 15.5497 20.8366 11.6691 20.9454C4.72191 20.9454 0.868397 24.7514 0.868397 29.2643C0.868397 39.8669 17.4765 35.9793 17.4765 41.4165C17.4765 43.5914 15.604 45.0594 12.0219 45.0594C10.0408 45.0051 8.08694 44.5429 6.29588 43.6729C4.01633 42.6127 1.32973 43.4011 0 45.5216C3.20221 48.4577 7.43565 50.0073 11.7776 49.8442C19.1861 49.8442 23.2296 46.1469 23.2296 41.2262C23.2296 30.2702 6.59439 33.9403 6.59439 29.074Z" />
      <path d="M91.6969 21.7607C88.2233 22.4947 85.7267 25.5668 85.7267 29.1281V40.0297C84.0442 42.2318 81.4933 43.5368 78.7252 43.6183C75.3059 43.6183 73.1349 42.259 73.1349 37.9636V21.5976C69.8513 21.5976 67.1647 24.2618 67.1647 27.5785V40.1929C67.1647 45.7932 70.1227 48.9196 76.0386 48.9196C79.7293 48.8924 83.2301 47.3156 85.7267 44.597V47.805C86.0795 51.6654 83.2572 55.0908 79.4037 55.4443C78.9423 55.4986 78.4539 55.4986 77.9925 55.4443C74.8175 55.6074 71.7781 54.3024 69.6885 51.9101L66.8933 56.2326C69.9056 59.0328 73.8948 60.528 77.9925 60.3377C85.9167 60.3377 91.7241 56.0695 91.7241 47.805L91.6969 21.7607Z" />
      <path d="M186.833 24.2649L184.092 29.3487C185.232 31.143 185.748 33.2363 185.585 35.3568C185.992 40.0056 182.545 44.1107 177.905 44.5185C177.634 44.5457 177.389 44.5457 177.118 44.5457C172.45 44.5457 168.651 40.7668 168.624 36.0636C168.624 35.819 168.624 35.6015 168.651 35.3568C168.57 32.5294 169.764 29.8108 171.908 27.9622L168.814 23.2318C164.798 25.9504 162.437 30.5177 162.572 35.3568C162.301 43.0776 168.325 49.5751 176.06 49.847C176.412 49.847 176.765 49.847 177.145 49.847C184.852 50.1732 191.365 44.1651 191.691 36.4442C191.718 36.0908 191.718 35.7102 191.691 35.3568C191.799 31.1158 190.008 27.065 186.833 24.2649Z" />
      <path
        opacity="0.25"
        d="M185.476 33.7798C187.077 31.8767 188.488 29.8378 189.683 27.6629C191.067 30.0553 191.745 32.7739 191.691 35.5197C191.691 36.6887 185.232 38.4014 185.395 37.4771C185.612 36.2537 185.639 35.0031 185.476 33.7798Z"
        fill="url(#paint0_linear_17785_8147)"
      />
      <path d="M186.317 20.9207C186.263 21.7907 186.073 22.6334 185.774 23.449C180.754 20.3226 176.846 21.5732 174.783 23.9656C172.341 26.7385 172.341 30.9252 174.756 33.7254C176.113 35.2478 178.013 36.1177 180.048 36.1721C180.944 36.1721 181.839 35.9818 182.653 35.6556C188.189 33.3447 191.554 24.2918 191.554 20.9207H186.317ZM180.564 32.1214C178.746 32.1214 177.253 30.6261 177.253 28.8047C177.253 26.9832 178.746 25.488 180.564 25.488C182.382 25.488 183.874 26.9832 183.874 28.8047C183.874 30.6533 182.409 32.1214 180.564 32.1214Z" />
      <defs>
        <linearGradient
          id="paint0_linear_17785_8147"
          x1="187.212"
          y1="32.1413"
          x2="192.645"
          y2="35.2873"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.001" stopColor="#272726" />
          <stop offset="0.176" stopColor="#272726" stopOpacity="0.765" />
          <stop offset="0.462" stopColor="#272726" stopOpacity="0.133" />
          <stop offset="0.513" stopColor="#272726" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Logo;
