import { DOMAIN } from 'src/constants/environment';

export function buildHref({ country, pathname = '' }) {
  const subDomain = country.subdomain || 'www';
  const topDomain = country.topdomain || 'com';

  const queryParams =
    !country.subdomain && !country.topdomain
      ? `?market=${country.market}&lang=${country.language}`
      : '';

  return `https://${subDomain}.${DOMAIN}.${topDomain}${pathname}${queryParams}`;
}
