import React from 'react';

import styles from './styles.module.css';
import PaxesConnected from 'src/pages/search/[country]/[type]/PaxesConnected';
import { SearchMode } from 'src/types/search';

export function Paxes() {
  return (
    <div data-testid="paxes-wrapper" className={styles.paxesWrapper}>
      <PaxesConnected mode={SearchMode.Full} />
    </div>
  );
}
