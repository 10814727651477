export const LOCALES_WIDGET = [
  'en-GB',
  'en-US',
  'en-IE',
  'de-DE',
  'de-AT',
  'es-ES',
  'fr-FR',
  'it-IT',
  'da-DK',
  'nl-NL',
  'nl-BE',
  'fr-BE',
  'fi-FI',
  'pl-PL',
  'pt-PT',
  'en-ZA',
  'en-CA',
  'fr-CA',
  'en-AU'
];

export const LANGUAGES_WIDGET = ['en', 'de', 'es', 'fr', 'it', 'da', 'nl', 'fi', 'pl', 'pt'];
