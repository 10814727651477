import styled from 'styled-components';

import { updatedMedia } from 'client/styles';
import { Content } from 'src/comps/KeyFeatures/styles';

export const KeyFeaturesBase = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${updatedMedia.tablet} {
    flex-direction: row;
  }
  ${updatedMedia.desktop} {
    gap: 48px;
  }

  ${Content} {
    p {
      color: #0f0f0f;
      font-size: 16px;
      line-height: 150%;
    }

    p:first-child {
      font-size: 21px;
      font-weight: 700;
      line-height: 130%;
      margin-bottom: 8px;
    }
  }
`;
