import React, { useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';
import noop from 'lodash/noop';

import Image from 'src/comps/Image';
import { PATH_IMAGES_CDN } from 'src/constants/environment';
import { useDevice } from 'src/hooks/useDevice';
import { BreakpointSizes } from 'src/types/device';

import { HeroBase, HeroWrapper } from './styles';

const ANIMATION_DURATION = 500;
const ON_SCREEN_TIME = 10000;

const TRANSITION_STYLES = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
  unmounted: { opacity: 0 },
};

const DEFAULT_STYLE = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: 'center',
  transition: `opacity ${ANIMATION_DURATION}ms ease-in-out`,
  opacity: 0,
};

export const buildImageAttrs = (imageIndex: number) => {
  const imageUrlBase = `${PATH_IMAGES_CDN}/home_v2_hero_slider/img0${imageIndex}`;

  return {
    alt: `Editorial image ${imageIndex}`,
    src: `${imageUrlBase}_767px_1x.webp`,
    srcSet: [
      `${imageUrlBase}_767px_1x.webp 767w`,
      `${imageUrlBase}_1024px_1x.webp 1024w`,
      `${imageUrlBase}_1200px_1x.webp 1200w`,
      `${imageUrlBase}_767px_2x.webp 1534w`,
      `${imageUrlBase}_1024px_2x.webp 2048w`,
      `${imageUrlBase}_767px_3x.webp 2301w`,
      `${imageUrlBase}_1200px_2x.webp 2400w`,
      `${imageUrlBase}_1024px_3x.webp 3072w`,
      `${imageUrlBase}_1200px_3x.webp 3600w`,
    ],
    sizes: ['(max-width: 1280px) calc(100vw - 80px)', '1200px'],
  };
};

const EDITORIAL_IMAGE_NUMBER = 6;
const editorialImages = Array.from({ length: EDITORIAL_IMAGE_NUMBER })
  .map((_, i) => i + 1)
  .map(buildImageAttrs);

const Hero = () => {
  const { activeBreakpoint } = useDevice();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const isMobile = activeBreakpoint < BreakpointSizes.M;

  const totalImages = editorialImages.length;
  const hasImages = totalImages > 0;

  useEffect(() => {
    if (isMobile) return noop;
    if (!hasImages) return noop;

    const delay = setTimeout(() => {
      setCurrentImageIndex((currentImageIndex + 1) % totalImages);
    }, ON_SCREEN_TIME);

    return () => clearTimeout(delay);
  });

  if (isMobile || !hasImages) {
    return <></>;
  }

  const image = editorialImages[currentImageIndex];

  return (
    <HeroWrapper data-testid="hero-wrapper">
      <HeroBase>
        <Transition in timeout={ANIMATION_DURATION} key={currentImageIndex}>
          {state => (
            <Image
              alt={image.alt}
              src={image.src}
              srcSet={image.srcSet}
              sizes={image.sizes}
              style={{
                ...DEFAULT_STYLE,
                ...TRANSITION_STYLES[state],
              }}
            />
          )}
        </Transition>
      </HeroBase>
    </HeroWrapper>
  );
};

export default Hero;
