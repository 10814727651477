import styled from 'styled-components';
import { TransitionGroup } from 'react-transition-group';

import { updatedMedia } from 'client/styles';

export const HeroBase = styled(TransitionGroup)`
  border-radius: 48px;
  height: 500px;
  width: 100%;
  max-width: var(--layout-max-width);
  overflow: hidden;
  position: relative;
`;

export const HeroWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 48px 40px;

  ${updatedMedia.desktop} {
    padding: 48px 80px;
  }
`;
