import React from 'react';
import NextHead from 'next/head';
import PropTypes from 'prop-types';

import { buildHref } from 'lib/domain';
import { COUNTRIES, DEFAULT_COUNTRY } from 'src/constants/countries';

const HrefLangs = ({ pathname }) => {
  const hrefDefault = buildHref({ country: DEFAULT_COUNTRY, pathname });

  return (
    <NextHead>
      <link rel="alternate" hrefLang="x-default" href={hrefDefault} />

      {Object.keys(COUNTRIES).map(key => {        
        const country = COUNTRIES[key];
        const href = buildHref({ country, pathname });
        const hrefLang = COUNTRIES[key].market === 'no' ? 'no' : country.locale;

        return <link key={key} rel="alternate" hrefLang={hrefLang} href={href} />;
      })}
    </NextHead>
  );
};

HrefLangs.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default HrefLangs;
