import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Script from 'next/script';
import { useIntersection } from 'react-use';

import { Layout, TrustPilotBase } from 'src/comps/TrustPilot/styles';
import { existLanguage, existsLocale } from 'src/comps/TrustPilot/functions';

import Loader from 'src/comps/Atom/Loader';

const TrustPilotWidget = ({ lazyLoad, className = '', ...props }) => {
  const layoutRef = useRef(null);

  const intersection = useIntersection(layoutRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  });

  return (
    <Layout className={className} ref={layoutRef}>
      {!lazyLoad || (intersection && intersection.intersectionRatio) ? (
        <TrustPilotWidgetContent {...props} />
      ) : (
        <Loader />
      )}
    </Layout>
  );
};

TrustPilotWidget.propTypes = {
  language: PropTypes.string,
  locale: PropTypes.string,
  lazyLoad: PropTypes.bool,
  className: PropTypes.string,
};

const TrustPilotWidgetContent = ({ locale, language }) => {
  const trustPilotRef = useRef(null);
  const isInvalid = !locale || !language || !existsLocale(locale) || !existLanguage(language);
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded && !isInvalid && typeof window !== 'undefined' && window.Trustpilot) {
      window.Trustpilot.loadFromElement(trustPilotRef.current, true);
      setLoaded(true);
    }
  }, [isLoaded, isInvalid]);

  if (isInvalid) {
    return <></>;
  }

  return (
    <>
      <TrustPilotBase>
        <div
          ref={trustPilotRef}
          className="trustpilot-widget"
          data-locale={locale}
          data-template-id="5419b6ffb0d04a076446a9af"
          data-businessunit-id="585c27800000ff00059990ca"
          data-style-width="100%"
          data-style-height="20px"
          data-theme="light"
          data-stars="4,5"
          data-review-languages={language}
          data-font-family="Roboto"
        >
          <a
            href="https://uk.trustpilot.com/review/stayforlong.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            Trustpilot
          </a>
        </div>
      </TrustPilotBase>
      <Script
        id="trustpilot-widget"
        src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        strategy="afterInteractive"
      />
    </>
  );
};

TrustPilotWidgetContent.propTypes = {
  language: PropTypes.string,
  locale: PropTypes.string,
};

export default TrustPilotWidget;
