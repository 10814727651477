import React from 'react';
import styles from './styles.module.css';
import { useIntl } from 'react-intl';

import Stars from 'client/components/Stars';
import Link from 'next/link';
import ChevronRight from 'client/icons/ChevronRight';

export const REVIEW_ITEMS = 4;

const Reviews = () => {
  const intl = useIntl();
  return (
    <div className={styles.container}>
      {[...Array(REVIEW_ITEMS)].map((x, i) => {
        const userName = intl.formatMessage({ id: `home.comment${i + 1}.username` });
        return (
          <div key={userName} className={styles.review}>
            <h6>{userName}</h6>
            <Stars
              stars={Number(intl.formatMessage({ id: `home.comment${i + 1}.rating` }))}
              color="grey0"
              size={13}
            />
            <p className={styles.reviewText}>
              {intl.formatMessage({ id: `home.comment${i + 1}.text` })}
            </p>
            <div>
              <Link href="/reviews" legacyBehavior={false} className={styles.reviewLink}>
                {intl.formatMessage({ id: 'global.read-more' })}
                <ChevronRight size={16} className={styles.reviewLinkIcon} />
              </Link>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Reviews;
