import React from 'react';

import { SearchMode } from 'src/types/search';
import { OmniSearch } from '../Omnisearch';

import styles from './styles.module.css';

interface IOmnisearchBtnProps {
  readonly onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  readonly onModeChange: (visualMode: SearchMode) => void;
}

export function OmnisearchBtn({ onClick, onModeChange }: IOmnisearchBtnProps) {
  return (
    <button
      className={styles.buttonWrapper}
      onClick={onClick}
      type="button"
      data-testid="omnisearch-btn"
    >
      <OmniSearch mode={SearchMode.Borderless} onChangeMode={onModeChange} />
    </button>
  );
}
