import React from 'react';

import styles from './styles.module.css';
import ConnectedDatePicker from 'src/pages/search/[country]/[type]/ConnectedDatePicker';
import { SearchMode } from 'src/types/search';

export function DatePicker() {
  return (
    <div data-testid="datepicker-wrapper" className={styles.datepickerWrapper}>
      <ConnectedDatePicker mode={SearchMode.Full} />
    </div>
  );
}
