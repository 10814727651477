import styled, { css } from 'styled-components';
import { colors } from 'client/styles';

export const CarouselDotsBase = styled.div`
  align-items: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0.15%, rgba(0, 0, 0, 0) 100%);
  bottom: 0;
  display: flex;
  height: 38px;
  justify-content: center;
  left: 0;
  position: absolute;
  width: 100%;
`;

export const Container = styled.div`
  overflow: hidden;
  width: 65px;
`;

function getPosition(current, total) {
  if (total > 5 && current > total - 3) {
    return total - 5;
  }

  if (total > 5 && current > 2) {
    return current - 2;
  }

  return 0;
}

export const Dots = styled.div`
  align-items: center;
  display: flex;
  transition: transform 0.25s ease-in-out;
  ${props => props.total < 5 && 'justify-content: center;'};
  ${props => `transform: translate(calc(${getPosition(props.current, props.total)} * -14px));`};
`;

export const Dot = styled.span`
  border-radius: 50%;
  flex: 0 0 auto;
  margin-right: 10px;

  &:last-child {
    margin: 0;
  }

  ${props =>
    props.selected
      ? css`
          height: 6px;
          width: 6px;
          background-color: transparent;
          border: 2px solid ${colors.white};
        `
      : css`
          height: 4px;
          width: 4px;
          background-color: ${colors.white};
          border: none;
        `}
`;
