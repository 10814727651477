import { useContext } from "react"
import { BusContext } from "src/context/bus"

const useBus = () => {
  const bus = useContext(BusContext)

  if (!bus) {
    throw new Error("Missing BusContext provider")
  }

  return bus;
}

export default useBus;
