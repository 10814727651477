import React, { useState, useEffect } from 'react';
import { BuilderComponent, builder } from '@builder.io/react';

import Modal from 'client/components/Modal';
import { VARIANT_MID_SCREEN } from 'client/components/Modal/constants';
import { useMarket } from 'src/hooks/useMarket';

const BuilderModal = () => {
  const { locale } = useMarket();
  const [show, setShow] = useState(false);
  const [content, setContent] = useState();
  const modalId = 'en-modal';

  useEffect(() => {
    builder
      .get(modalId, {
        url: window.location.pathname,
        userAttributes: { locale },
        options: {
          locale,
        },
      })
      .promise()
      .then(res => {
        setContent(res);
        setShow(!!res);
      });
  }, [modalId, locale]);

  if (!show) {
    return null;
  }

  return (
    <Modal show={show} handleClose={() => setShow(false)} variant={VARIANT_MID_SCREEN}>
      <BuilderComponent model={modalId} content={content} locale={locale} />
    </Modal>
  );
};

export default BuilderModal;
