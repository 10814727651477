import React, { CSSProperties } from 'react';
import Link from 'next/link';

import styles from './styles.module.css';

interface DestinationCardProps {
  title: string;
  subtitle: string;
  image: string;
  slug: string;
}

const DestinationCard = (props: DestinationCardProps) => {
  const { title, subtitle, image, slug } = props;

  return (
    <Link
      href={slug}
      className={styles.card}
      legacyBehavior={false}
      style={
        {
          '--imageUrl': `url(${image})`,
        } as CSSProperties
      }
      data-testid="destination-card"
    >
      <div className={styles.cardContent}>
        <h6 className={styles.cardTitle}>{title}</h6>
        <p className={styles.cardSubtitle}>{subtitle}</p>
      </div>
    </Link>
  );
};

export default DestinationCard;
