import React from 'react';

import { SearchMode } from 'src/types/search';
import ConnectedSuggester from 'src/pages/search/[country]/[type]/SuggesterConnected';

import styles from './styles.module.css';

export function Suggester() {
  return (
    <div className={styles.suggesterWrapper}>
      <ConnectedSuggester className="suggester-wrapper" mode={SearchMode.Full} />
    </div>
  );
}
