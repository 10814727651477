import { RefObject, useEffect } from 'react';
import { useRafState } from 'react-use';
import debounce from 'lodash/debounce';

export interface State {
  x: number;
  y: number;
}

const useScrollEnd = (ref: RefObject<HTMLElement>, delay = 100): State => {
  const [state, setState] = useRafState<State>({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    const handler = debounce(() => {
      if (ref.current) {
        setState({
          x: ref.current.scrollLeft,
          y: ref.current.scrollTop,
        });
      }
    }, delay);

    const { current } = ref;

    if (current) {
      current.addEventListener('scrollend', handler, {
        capture: false,
        passive: true,
      });
    }

    return () => {
      if (current) {
        current.removeEventListener('scrollend', handler);
      }
      handler.cancel();
    };
  }, [ref, delay, setState]);

  return state;
};

export default useScrollEnd;
