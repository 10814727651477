import React from 'react';
import Script from 'next/script';
import { useMarket } from 'src/hooks/useMarket';

const Maze = () => {
  const { market } = useMarket();
  if (!process.env.NEXT_PUBLIC_MAZE_KEY?.length) return null;
  if (market !== 'us' && market !== 'uk') return null;
  return (
    <Script id="maze">
      {`
          (function (m, a, z, e) {
            var s, t;
            try {
              t = m.sessionStorage.getItem('maze-us');
            } catch (err) {}

            if (!t) {
              t = new Date().getTime();
              try {
                m.sessionStorage.setItem('maze-us', t);
              } catch (err) {}
            }

            s = a.createElement('script');
            s.src = z + '?apiKey=' + e;
            s.async = true;
            a.getElementsByTagName('head')[0].appendChild(s);
            m.mazeUniversalSnippetApiKey = e;
          })(window, document, 'https://snippet.maze.co/maze-universal-loader.js', '${process.env.NEXT_PUBLIC_MAZE_KEY}');
        `}
    </Script>
  );
};

export default Maze;
