import React from 'react';
import OmniSearchBase from 'src/pages/search/[country]/[type]/OmniSearch';
import { SearchMode } from 'src/types/search';

import styles from './styles.module.css';
import { useSearch } from 'src/hooks/useSearch';

interface IOmniSearchProps {
  readonly mode: SearchMode;
  readonly onChangeMode: (mode: SearchMode) => void;
}

export const OmniSearch = ({ mode, onChangeMode }: IOmniSearchProps) => {
  const { oldSearchUrl } = useSearch();
  return (
    <div data-testid="omnisearch-wrapper" className={styles.omnisearchWrapper}>
      <OmniSearchBase
        mode={mode}
        onChangeMode={onChangeMode}
        preventNavigation={false}
        searchUrl={oldSearchUrl}
      />
    </div>
  );
};
