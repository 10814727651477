import React from 'react';

import { MarqueeBase, MarqueeItem, MarqueeSlide, Slogan } from './styles';

const SflLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
    <path d="M55.7554 17.9975C56.3016 16.493 56.6492 14.9383 56.7485 13.3335L66.3319 13.3836C66.3319 16.1352 65.1362 20.9388 62.9254 25.8048C63.2323 26.3414 63.5201 26.8869 63.7888 27.4403C65.7135 31.2649 66.7135 35.5415 66.6297 39.9134C66.6794 40.5653 66.6794 41.2674 66.6297 41.9194C66.0339 56.162 54.1167 67.2452 40.0148 66.6434H38.0286C23.8769 66.1419 12.8536 54.156 13.3501 39.9134C13.1018 30.9866 17.4218 22.5614 24.7707 17.5965L30.3817 26.3226C26.459 29.7329 24.2742 34.7479 24.4232 39.9635C24.3735 40.4149 24.3735 40.8161 24.3735 41.2674C24.4231 49.9434 31.3748 56.9143 39.9154 56.9143C40.412 56.9143 40.8589 56.9143 41.3554 56.8641C48.4134 56.2388 53.9618 50.9009 55.2058 44.1916C55.0893 44.1147 55.0369 44.0102 55.0605 43.8751C55.4545 41.6366 55.5066 39.3487 55.2166 37.1093C53.6511 38.5662 51.9397 39.7477 50.0948 40.5149C48.6051 41.1167 46.9665 41.4678 45.3279 41.4678C41.6038 41.3675 38.128 39.7627 35.6452 36.9543C31.1763 31.7888 31.226 24.0656 35.6949 18.9503C39.4687 14.5873 46.5693 12.2302 55.7554 17.9975ZM40.2135 27.8771C40.2135 31.2371 42.9445 33.9954 46.2714 33.9954C49.5982 33.9954 52.3293 31.2873 52.3293 27.8771C52.3293 24.517 49.5982 21.7587 46.2714 21.7587C42.9445 21.7587 40.2135 24.517 40.2135 27.8771Z" fill="white"/>
  </svg>
);

const Marquee = () => (
  <MarqueeBase data-testid="marquee-wrapper">
    <MarqueeSlide>
      <MarqueeItem>
        <Slogan color="marquee" size="xxxxl" fontFamily="secondary">
          your stay, your rules
        </Slogan>
        <SflLogo />
      </MarqueeItem>
      <MarqueeItem>
        <Slogan color="marquee" size="xxxxl" fontFamily="secondary">
          your stay, your rules
        </Slogan>
        <SflLogo />
      </MarqueeItem>
    </MarqueeSlide>
    <MarqueeSlide>
      <MarqueeItem>
        <Slogan color="marquee" size="xxxxl" fontFamily="secondary">
          your stay, your rules
        </Slogan>
        <SflLogo />
      </MarqueeItem>
      <MarqueeItem>
        <Slogan color="marquee" size="xxxxl" fontFamily="secondary">
          your stay, your rules
        </Slogan>
        <SflLogo />
      </MarqueeItem>
    </MarqueeSlide>
  </MarqueeBase>
);

export default Marquee;
