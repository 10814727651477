import React, { Fragment } from 'react';

import { KeyFeature, keyFeatures } from 'src/comps/KeyFeatures';

import { KeyFeaturesBase } from './styles';

const KeyFeatures = () => {
  return (
    <KeyFeaturesBase>
      {keyFeatures.map(keyFeatureProps => (
        <Fragment key={keyFeatureProps.title}>
          <KeyFeature {...keyFeatureProps} iconSize={30} />
        </Fragment>
      ))}
    </KeyFeaturesBase>
  );
};

export default KeyFeatures;
