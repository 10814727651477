import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Title } from './styles';

export function HomeTitle() {
  const intl = useIntl();
  const titles = [
    intl.formatMessage({ id: 'hero.for-your-holidays' }),
    intl.formatMessage({ id: 'hero.for-your-adventures' }),
    intl.formatMessage({ id: 'hero.for-your-getaways' }),
    intl.formatMessage({ id: 'hero.for-your-discoveries' }),
    intl.formatMessage({ id: 'hero.for-your-dates' }),
    intl.formatMessage({ id: 'hero.for-your-kids' }),
  ];
  return (
    <Title as="h1" size="xxxl" color="berlin">
      <FormattedMessage id="hero.best-hotels" />
      {titles.map(title => {
        const words = title.split(' ');
        const lastWord = words.pop();
        return (
          <span key={lastWord}>
            {words.join(' ')} <em>{lastWord}</em>
          </span>
        );
      })}
    </Title>
  );
}
