import styled from 'styled-components';

import { Heading } from 'src/styles/typography/typography';
import { updatedMedia } from 'client/styles';

export const Title = styled(Heading)`
  display: flex;
  justify-content: center;
  font-weight: 700;
  text-indent: 8px;
  position: relative;
  min-height: 100px;
  ${updatedMedia.tablet} {
    min-height: 135px;
  }
  ${updatedMedia.onlyMobile} {
    font-size: 28px;
  }
  span {
    display: block;
    position: absolute;
    opacity: 0;
    overflow: hidden;
    transform: translateY(35px);
    animation: rotateTitle 60s ease-in-out infinite 0s;
    animation-delay: 0s;
    ${updatedMedia.tablet} {
      transform: translateY(65px);
    }
    em {
      color: ${({ theme }) => theme.colors.brand1};
    }
    &:nth-child(2) {
      animation-delay: 10s;
    }
    &:nth-child(3) {
      animation-delay: 20s;
    }
    &:nth-child(4) {
      animation-delay: 30s;
    }
    &:nth-child(5) {
      animation-delay: 40s;
    }
    &:nth-child(6) {
      animation-delay: 50s;
    }
  }

  @keyframes rotateTitle {
    0% {
      opacity: 0;
    }
    2% {
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    17% {
      opacity: 1;
    }
    20% {
      opacity: 0;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;
